import handlesUserAccess from "@/mixins/handlesUserAccess";

export default {
  mixins: [handlesUserAccess],

  methods: {
    programPrice(program) {
      if (program.is_free) return "Free Program";
      if (program.is_vod && this.paidForSubscription)
        return program.subscribers_price.formatted;
      if (program.is_vod) return program.price.formatted;

      return null;
    },

    programDate(program) {
      if (program.dates && program.dates.length) {
        return program.dates[0];
      }

      return null;
    },
  },
};
