import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      user: state => state.auth.profile,
    }),

    ...mapGetters(["paidForSubscription"]),
  },

  methods: {
    paidForProgram(programId) {
      return this.user && this.user.vod_programs.includes(programId);
    },

    paidForProgramOnly(programId) {
      return this.paidForProgram(programId) && !this.paidForSubscription;
    },

    paidForProgramAndSubscription(programId) {
      return this.paidForProgram(programId) && this.paidForSubscription;
    },
  },
};
