<template>
  <div>
    <section class="section dark-section bujin-black-section section-sm py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center py-5">
              <h1>
                <strong>{{ language.seminars_title }}</strong>
              </h1>
            </div>
            <div class="row row-grid justify-content-center">
              <div v-if="programs.length == 0">
                <img src="/img/icons/loading.gif" class="br-15 multiply" />
              </div>

              <div
                v-else
                class="col-lg-4 mb-4"
                v-for="(program, index) in programs"
                :key="index"
              >
                <div class="border-0 shadow" hover>
                  <div>
                    <router-link
                      :to="{
                        name: 'media-detail',
                        params: { slug: program.slug },
                      }"
                    >
                      <img
                        v-lazy="program.img_poster"
                        class="mw-100 br-5 rounded"
                      />
                    </router-link>
                  </div>
                  <h6 class="py-1">
                    <strong>{{ program.title }}</strong>
                  </h6>

                  <div
                    class="d-flex align-items-center justify-content-between mb-3"
                  >
                    <div
                      v-if="programDate(program)"
                      class="text-primary text-xs font-weight-700"
                    >
                      <span class="d-block">{{
                        programDate(program).date
                      }}</span>
                      <span class="d-block">{{
                        programDate(program).timezone
                      }}</span>
                    </div>

                    <span
                      v-if="programPrice(program)"
                      class="text-primary text-sm font-weight-700"
                    >
                      {{ programPrice(program) }}
                    </span>
                  </div>

                  <base-button
                    tag="router-link"
                    :to="{
                      name: 'media-detail',
                      params: { slug: program.slug },
                    }"
                    type="primary"
                    class="w-100 mt-2"
                  >
                    {{ language.call_to_action }}
                  </base-button>
                  <p class="description limited-description mt-3">
                    {{ program.summary }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section dark-section bujin-black-section section-lg pt-0">
      <div class="container">
        <card gradient="danger" no-body shadow-size="lg" class="border-0">
          <div class="p-5">
            <div class="row align-items-center">
              <div class="col-lg-8">
                <h3 class="text-white font-weight-800">
                  Enjoy on your favorite device
                </h3>
                <p class="lead text-white mt-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum
                </p>
              </div>
              <div class="col-lg-3 ml-lg-auto">
                <a
                  href=""
                  class="d-block col-10 col-sm-8 col-md-6 col-lg-12 mx-auto mb-3"
                >
                  <img
                    src="@/assets/img/apple_app_store.svg"
                    class="img-fluid"
                  />
                </a>

                <a
                  href=""
                  class="d-block col-10 col-sm-8 col-md-6 col-lg-12 mx-auto mb-3"
                >
                  <img
                    src="@/assets/img/roku_app_store.svg"
                    class="img-fluid"
                  />
                </a>

                <a
                  href=""
                  class="d-block col-10 col-sm-8 col-md-6 col-lg-12 mx-auto"
                >
                  <img
                    src="@/assets/img/google_play_app_store.svg"
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import handlesProgram from "@/mixins/handlesProgram";

export default {
  name: "Programs",

  mixins: [handlesProgram],

  created() {
    this.fetchPrograms();
  },
  data: function () {
    return {};
  },
  computed: {
    language() {
      return this.$store.state.language;
    },

    actionMenu() {
      return this.$store.state.action_menu;
    },

    programs() {
      return this.$store.state.programs.filter((p) => p.format == "seminar");
    },
  },

  methods: {
    fetchPrograms() {
      return this.axios("/programs").then((response) => {
        this.$store.commit("setPrograms", response.data.data);
      });
    },
  },
};
</script>
